import React, {useState, useEffect, useRef} from "react";
import {Box, CircularProgress, Container, List, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import useSocket from "../../omnia/hooks/use-socket";
import { produce } from "immer";
import moment from "moment";
import Message from "./chat-message";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@mui/lab";

function ChatViewer({chat, smallVersion, maxWidth, height, onUpdate, endpointPrefix, onPrecedingChange}){

    const [ initial, setInitial ] = useState(true);
    const { t } = useTranslation();
    const [ alternatives, setAlternatives ] = useState([]);
    const [ finalMessages, setFinalMessages ] = useState([]);
    const [ messages, setMessages ] = useState([]);
    const [ typing, setTyping ] = useState([]);
    const messagesEndRef = useRef(null);

    const appendMessages = (newMessages) => {

        setMessages(prev => produce(prev, draft => {
            newMessages.forEach(newMessage => {
                const index = draft.findIndex(m => m.id === newMessage.id);
                if(index >= 0){
                    draft[index] = newMessage;
                } else {
                    draft.push(newMessage);
                }
            });
            // sort the messages by date
            draft.sort((a, b) => (new Date(b.sent_at)).getTime() < (new Date(a.sent_at)).getTime() ? 1 : -1);
        }))

        // setMessages(prev => produce(prev.concat(newMessages.filter(m => !prev.map(pm => pm.id).includes(m.id))), draft => {
        //     draft.sort((a, b) => (new Date(b.sent_at)).getTime() < (new Date(a.sent_at)).getTime() ? 1 : -1);
        // }));

        setTimeout(() => {
            setInitial(false);
        }, 2000);
    }

    const scrollDown = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const { isConnected} = useSocket(endpointPrefix+ '/chat/' + chat?.id, (data) => {

        if(data['kind'] === 'messages'){
            appendMessages(data['data']);
        }

        if(data['kind'] === 'message'){
            appendMessages([data['data']]);
        }

        if(data['kind'] === 'start-typing'){
            setTyping(prev => {
                if(!prev.includes(data['data'])){
                    return prev.concat(data['data']);
                }
                return prev;
            });
        }

        if(data['kind'] === 'stop-typing'){
            setTyping(prev => prev.filter(t => t !== data['data']));
        }

        if(data['kind'] === 'chat-update'){
            if(onUpdate){
                onUpdate(data['data']);
            }
        }

    }, true);

    const handleAlternativeChange = (oldIds, chosenId) => {
        setAlternatives(prev => {
            return prev.filter(id => !oldIds.includes(id)).concat(chosenId);
        });
    }

    useEffect(() => {
        scrollDown();
    }, [finalMessages, typing]);

    useEffect(() => {
        const sortedMessages = produce(messages, draft => {
            draft.sort((a, b) => (new Date(b.sent_at)).getTime() < (new Date(a.sent_at)).getTime() ? 1 : -1);
        });
        let m = sortedMessages?.filter(m => m?.preceding === null && alternatives.includes(m?.id))?.length > 0 ?
            sortedMessages?.filter(m => m?.preceding === null && alternatives.includes(m?.id))[0] :
            sortedMessages?.length > 0 ? sortedMessages[0] : null;
        // if(alternatives?.length > 0){
        //     // check if any alternative has preceding null
        //     m = sortedMessages?.filter(m => m?.preceding === null && alternatives.includes(m?.id))?.length > 0 ?
        //         sortedMessages?.filter(m => m?.preceding === null && alternatives.includes(m?.id))[0] :
        //         sortedMessages?.length > 0 ? sortedMessages[0] : null;
        // } else {
        //     m = sortedMessages?.length > 0 ? sortedMessages[0] : null;
        // }
        let computedMessages = [];
        let next_messages = [];
        let alternative_choices_hits = [];

        while(m !== null){
            // Add the current message to the final list
            computedMessages.push(m);
            // Compute a list of following messages
            next_messages = sortedMessages.filter(mgs => mgs?.preceding === m?.id);
            // Case if there are no further messages (end loop)
            if(next_messages?.length === 0){
                m = null;
                break;
            }
            // Case if there is exactly one message following the current one (easy case)
            else if(next_messages?.length === 1){
                m = next_messages[0];
            }
            // Case to check if it is contained in the chosen alternatives
            else {
                if(alternatives?.length > 0){
                    alternative_choices_hits = next_messages?.filter(nm => alternatives?.includes(nm?.id));
                    if(alternative_choices_hits?.length > 0){
                        m = alternative_choices_hits[0];
                    } else {
                        m = next_messages[0];
                    }
                } else {
                    m = next_messages[0];
                }
            }
        }
        if(onPrecedingChange){
            onPrecedingChange(computedMessages[computedMessages.length - 1]?.id || null);
        }

        setFinalMessages(computedMessages);

    }, [messages, alternatives]);

    if(!isConnected) {
        return (
            <div style={{height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </div>
        )
    }

    const messageStack = (
        <Stack spacing={0.2} sx={{maxWidth: '100%'}}>
            {finalMessages.map((message, index) => (
                <Message
                    key={'message-' + index}
                    chat={chat}
                    endpointPrefix={endpointPrefix}
                    onAlternativeChange={handleAlternativeChange}
                    initial={initial}
                    index={index}
                    smallVersion={smallVersion}
                    message={message}
                    lastUserMessage={
                        (index === messages.length - 1) ||
                        (
                            (messages[index].created_by.id !== messages[index + 1].created_by.id) ||
                            (
                                (((new Date(messages[index + 1].sent_at)) - (new Date(messages[index].sent_at))) > 300000) &&
                                ((moment(messages[index + 1].sent_at).fromNow()) !== (moment(messages[index].sent_at).fromNow()))
                            )
                        )
                    }
                    firstUserMessage={
                        (index === 0) ||
                        (
                            (messages[index].created_by.id !== messages[index - 1].created_by.id) ||
                            (
                                (((new Date(messages[index].sent_at)) - (new Date(messages[index - 1].sent_at))) > 300000) &&
                                ((moment(messages[index].sent_at).fromNow()) !== (moment(messages[index - 1].sent_at).fromNow()))
                            )
                        )
                    }
                />
            ))}
            {typing.map(user => (
                <Box pl={smallVersion ? 1 : 0} pt={smallVersion ? 0 : 4} key={'typing-' + user}>
                    {smallVersion && (
                        <Skeleton variant="rounded" sx={{borderRadius: '15px'}} width={218} height={33} />
                    )}
                    <Typography variant={smallVersion ? "caption" : "subtitle2"}>
                        {t('core.is_typing', {name: user})}
                    </Typography>
                </Box>
            ))}
            <div ref={messagesEndRef}/>
        </Stack>
    )

    if(height){
        return (
            <List sx={{height: height, overflow: 'auto', px: 2}}>
                {messageStack}
            </List>
        )
    }

    return (
        <Container maxWidth={maxWidth} sx={{pb: 20}}>
            {messageStack}
        </Container>
    )
}

ChatViewer.propTypes = {
    chat: PropTypes.object.isRequired,
    smallVersion: PropTypes.bool,
    height: PropTypes.number,
    maxWidth: PropTypes.string,
    endpointPrefix: PropTypes.string,
    onUpdate: PropTypes.func,
    onPrecedingChange: PropTypes.func
}

ChatViewer.defaultProps = {
    maxWidth: false,
    endpointPrefix: 'bh',
    height: 400,
    smallVersion: false,
    onUpdate: null,
    onPrecedingChange: null
}

export default ChatViewer;