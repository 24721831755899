import {
    SET_CHAT_OPEN
} from 'src/storage/actions/beyond-actions'
import { produce } from "immer";

const initialState = {
    chatOpen: false,
};

const beyondHubReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CHAT_OPEN:
            return produce(state, draft => {
                draft.chatOpen = action.payload;
            })

        default: {
            return state;
        }
    }

};

export default beyondHubReducer;
