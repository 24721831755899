import {useTheme} from "@mui/system";
import {useIsMobile} from "../../omnia/hooks/use-is-mobile";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardMedia,
    Fade,
    IconButton,
    Link,
    Pagination,
    Popover,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PostFileItem from "../../omnia/components/modules/core/connect/post-card/post-file-item";
import moment from "moment/moment";
import {Lightbox} from "react-modal-image";
import PropTypes from "prop-types";
import HtmlWrapper from "../../omnia/components/elements/html-wrapper";
import {APP_SETTING} from "../../setup";
import removeHtmlTags from "../../omnia/utils/remove-html-tags";
import {useNotifications} from "../../omnia/hooks/use-notifications";
import {useTranslation} from "react-i18next";
import OnIcon from "../../omnia/components/elements/icon";
import {QuillEditor} from "../../omnia/components/elements/quill-editor";
import SaveButton from "../../omnia/components/elements/save-button";
import useOmniaApi from "../../omnia/hooks/use-omnia-api";
import _ from "lodash";
import {TextEditor} from "../../omnia/components/elements/text-editor/text-editor";

export const MessageRenderer = (props) => {
    const {
        message,
        chat,
        endpointPrefix,

        contentType,
        position,
        body,
        isEmoji,
        files,
        onFileUpdate,
        onAlternativeChange,
        smallVersion,

        isShortMessage,
        lastUserMessage,
        firstUserMessage,
        ...other
    } = props;

    const {t} = useTranslation();
    const theme = useTheme();
    const { isMobile } = useIsMobile();
    const [messageContent, setMessageContent] = useState(body);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openedFile, setOpenedFile] = useState(null);
    const { post } = useOmniaApi();
    const {notify} = useNotifications();
    const [anchorEl, setAnchorEl] = useState(null);
    const [copied, setCopied] = useState(false);

    const largeMessage = (
        <Box sx={firstUserMessage ? {pt: 4} : {}}>
            <Stack direction="column" spacing={1}>
                {firstUserMessage && (
                    <Typography
                        color="inherit"
                        variant="h6"
                    >
                        {message.created_by.name}
                    </Typography>
                )}
                <Typography
                    color="inherit"
                    variant="subtitle1"
                >
                    <HtmlWrapper html={body} />
                </Typography>
                {files && (files.length > 0) && (
                    <Box>
                        <PerfectScrollbar>
                            <Box style={{maxHeight: 500}}>
                                {files.map((file, i) => {
                                    return (
                                        <PostFileItem
                                            key={message.id + '-file-' + i}
                                            simpleIcon={true}
                                            file={file}
                                            showDivider={i < files.length - 1}
                                            deleteCallback={onFileUpdate}
                                            onUpdate={onFileUpdate}
                                            sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                        />
                                    )
                                })}
                            </Box>
                        </PerfectScrollbar>
                    </Box>
                )}
            </Stack>
        </Box>
    )

    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setIsEditing(true);
        handlePopoverClose();
    }

    const handleAlternative = (event, value) => {
        onAlternativeChange?.(message?.alternatives, message?.alternatives?.[value - 1] || message?.id);
    }

    const handleDoneEditing = () => {
        setLoading(true);
        const data = {
            message: messageContent,
            preceding: message.preceding,
        }
        post(endpointPrefix + '/chats/' + message?.chat + '/new_message', data).then((alternativeMessage) => {
            onAlternativeChange?.(alternativeMessage?.alternatives, alternativeMessage?.id);
            setIsEditing(false);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(removeHtmlTags(body)).then(function () {
            setCopied(true);
            setTimeout(() => {
                handlePopoverClose();
            }, 500);
        }).catch(function () {
            notify(t('notify.error'), 'error');
        });
    }

    const open = Boolean(anchorEl);

    const chatBubble = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: position === 'right' ? 'flex-end' : 'flex-start',
                    maxWidth: '100%'
                }}
                id="ChatBubble"
                {...other}
            >
                <Stack
                    alignItems="flex-start"
                    direction={position === 'right' ? 'row-reverse' : 'row'}
                    spacing={2}
                    sx={{
                        maxWidth: 'min(100%, 500px)',
                        ml: position === 'right' ? 'auto' : 0,
                        mr: position === 'left' ? 'auto' : 0
                    }}
                >
                    <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                        {isEmoji ? (
                            <Box style={{
                                fontSize: smallVersion ? 40 : 50,
                                textAlign: position
                            }}>
                                {body}
                            </Box>
                        ) : (
                            <Card
                                sx={isEditing ? {} : {
                                    backgroundColor: position === 'right' ? 'primary.main' : 'background.paper',
                                    color: position === 'right' ? 'primary.contrastText' : 'text.primary',
                                    borderRadius: '20px',
                                }}
                                onContextMenu={handleContextMenu}
                            >
                                <Box>
                                    {(firstUserMessage && (position === "left")) && (
                                        <Box sx={{
                                            px: 1.75,
                                            py: smallVersion ? 0 : 0.75,
                                            pt: 0.75,
                                        }}>
                                            <Link
                                                underline="hover"
                                                color="inherit"
                                                component={RouterLink}
                                                to={'#'}
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontWeight: 600,
                                                    fontSize: smallVersion ? 12 : 13,
                                                }}
                                            >
                                                {message.created_by.name}
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                                {contentType === 'image' && (
                                    <CardMedia
                                        component="img"
                                        onClick={() => setOpenedFile(body)}
                                        image={body}
                                        height="auto"
                                        maxWidth={"100%"}
                                    />
                                )}
                                {contentType === 'text' && (
                                    <>
                                        {isEditing ? (
                                            <>
                                                <QuillEditor
                                                    value={messageContent}
                                                    onChange={(value) => setMessageContent(value)}
                                                    multiLine={true}
                                                    noBorder={true}
                                                />
                                                <Stack direction="row" justifyContent="space-between" spacing={1} p={1}>
                                                    <Button size="small" color="primary" onClick={() => setIsEditing(false)}>
                                                        {t('common.cancel')}
                                                    </Button>
                                                    <SaveButton
                                                        loading={loading}
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleDoneEditing}
                                                        noIcon
                                                        label={t('common.done')}
                                                    />
                                                </Stack>
                                            </>
                                        ) : (
                                            <Typography
                                                sx={{
                                                    px: 1.5,
                                                    py: 0.75
                                                }}
                                                color="inherit"
                                                variant={(smallVersion || isMobile) ? "body2" : "body1"}
                                            >
                                                <HtmlWrapper html={body} />
                                            </Typography>
                                        )}
                                        {files && (files.length > 0) && (
                                            <Box mt={2}>
                                                <PerfectScrollbar>
                                                    <Box style={{maxHeight: 500}}>
                                                        {files.map((file, i) => {
                                                            return (
                                                                <PostFileItem
                                                                    key={message.id + '-file-' + i}
                                                                    simpleIcon={true}
                                                                    file={file}
                                                                    showDivider={i < files.length - 1}
                                                                    deleteCallback={onFileUpdate}
                                                                    onUpdate={onFileUpdate}
                                                                    sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                                                />
                                                            )
                                                        })}
                                                    </Box>
                                                </PerfectScrollbar>
                                            </Box>
                                        )}
                                    </>
                                )}

                            </Card>
                        )}
                    </Box>
                </Stack>
            </Box>
            {/*<Box>*/}
            {lastUserMessage && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
                    }}
                    pl={position === 'right' ? 0 : ((smallVersion || !chat.is_group_chat) ? 1 : 7)}
                    pr={position === 'left' ? 0 : 1}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        variant="caption"
                    >
                        {moment(message.sent_at).fromNow()}
                    </Typography>
                </Box>
            )}
            {/*</Box>*/}
            {openedFile && (
                <div style={{zIndex: 1300}}>
                    <Lightbox

                        large={openedFile}
                        onClose={() => setOpenedFile(null)}
                    />
                </div>
            )}
        </>
    )

    if(!body)
        return null;

    return (
        <>
            <Box
                sx={firstUserMessage ? {mt: smallVersion ? 2 : 4, maxWidth: '100%'} : {maxWidth: '100%', mt: smallVersion ? 0.1 : 1}}
                id="MessageRendererComponent"
            >
                {(smallVersion !== (position === 'right')) ? chatBubble : (
                    <>
                        {isShortMessage ? chatBubble : largeMessage}
                    </>
                )}
                {message?.alternatives?.length > 1 && (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{width: '100%'}}
                    >
                        <Pagination
                            count={message?.alternatives?.length}
                            page={_.indexOf(message?.alternatives || [], message?.id) + 1}
                            onChange={handleAlternative}
                            size="small"
                        />
                    </Stack>
                )}
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: position === 'right' ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: position,
                }}
            >
                <Stack direction="row" spacing={0} sx={{p: 0.5}}>
                    {(position === 'right' && contentType === 'text') && (
                        <Tooltip title={t('common.edit')} placement="top">
                            <IconButton onClick={handleEdit}>
                                <SvgIcon fontSize="small">
                                    <OnIcon iconName="Edit05"/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    )}

                    {contentType === 'text' && (
                        <Tooltip title={copied ? t('common.copied') : t('common.copy')}
                                 placement="top">
                            <IconButton onClick={handleCopy}>
                                <SvgIcon fontSize="small">
                                    <OnIcon iconName="Copy01"/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </Popover>
        </>
    )
};

const Message = ({message, chat, index, initial, smallVersion, lastUserMessage, firstUserMessage, endpointPrefix, onAlternativeChange}) => {
    const [show, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [featureImages, setFeatureImages] = useState([]);
    const [isEmoji, setIsEmoji] = useState(false);

    const updateFiles = () => {
        // TODO: We might need to do this sometime?
        setFeatureImages([]);
    }

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, initial ? (index * 25) : 0);
    }, [index, initial]);

    useEffect(() => {
        if ((message.files.length > 0)) {
            updateFiles();
        } else {
            setFiles([]);
            setFeatureImages([]);
        }
        // check if message is emoji
        setIsEmoji(/\p{Extended_Pictographic}/u.test(message.body) && !/[a-zA-Z]/.test(message.body));
    }, [message]);

    const myMessage = message.created_by?.type === 'user';

    return (
        <Fade in={show} timeout={APP_SETTING?.transitionDuration || 500}>
            <Box sx={{maxWidth: '100%'}} id="MessageComponent">
                {featureImages.map((feature, index) => (
                    <MessageRenderer
                        message={message}
                        chat={chat}
                        key={message.id + '-' + index}
                        endpointPrefix={endpointPrefix}
                        onAlternativeChange={onAlternativeChange}

                        contentType={'image'}
                        position={myMessage ? 'right' : 'left'}
                        isEmoji={isEmoji}
                        body={feature}
                        lastUserMessage={false}
                        firstUserMessage={false}
                        isShortMessage={false}

                        smallVersion={smallVersion}
                    />
                ))}
                <MessageRenderer
                    message={message}
                    key={message.id}
                    chat={chat}
                    endpointPrefix={endpointPrefix}
                    onAlternativeChange={onAlternativeChange}

                    contentType={'text'}
                    files={files}
                    onFileUpdate={updateFiles}
                    position={myMessage ? 'right' : 'left'}
                    isEmoji={isEmoji}
                    body={message.body}
                    lastUserMessage={lastUserMessage}
                    firstUserMessage={firstUserMessage}
                    isShortMessage={message?.body?.length < 400}

                    smallVersion={smallVersion}
                />
            </Box>
        </Fade>
    )
};

Message.propTypes = {
    message: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    smallVersion: PropTypes.bool.isRequired,
    lastUserMessage: PropTypes.bool,
    firstUserMessage: PropTypes.bool,
    initial: PropTypes.bool,
    index: PropTypes.number,
    endpointPrefix: PropTypes.string.isRequired,
    onAlternativeChange: PropTypes.func,
}

MessageRenderer.propTypes = {
    message: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    contentType: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    isEmoji: PropTypes.bool.isRequired,
    files: PropTypes.array,
    onFileUpdate: PropTypes.func,
    smallVersion: PropTypes.bool,
    lastUserMessage: PropTypes.bool,
    firstUserMessage: PropTypes.bool,
    isShortMessage: PropTypes.bool,
    endpointPrefix: PropTypes.string.isRequired,
    onAlternativeChange: PropTypes.func,
}

export default Message;